.navbar {
  background-color: #fff;
  height: 55px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #1b2da1;
  width: 79px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: center;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

@media (max-width: 984px) {
  .nav-menu {
    height: 0;
  }

  .nav-menu-items {
    background-color: #1b2da1;
    width: 100%;
    overflow: hidden;
    position: fixed;
    bottom: -20px;
    left: 0;
  }

  .nav-menu-items li {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
}

.navbar-toggle {
  background-color: #1b2da1;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 2px 5px 25px;
}

